import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { WorkPlatformService } from '@/sevices/index';
import { namespace } from 'vuex-class';
import { service } from '@cloudpivot-hermes/nail-free-login';
import { isPhone } from '@/common/utils';
const workPlatformModule = namespace('workPlatform');
const Global = namespace('global');
let ComponentName = class ComponentName extends Vue {
    get IsDingTalk() {
        return sessionStorage.IS_DINGTALK === 'true';
    }
    get isFreeLoginPlatform() {
        return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
    }
    get ISWECHAT() {
        return sessionStorage.IS_WECHAT === 'true';
    }
    clickHandler(item) {
        // htttp://xxxxx/#/appCode/appVersion?groupCode=1&moduleCode=1&pageGroupCode=1&pageCode=1
        const params = {
            appCode: item.appCode,
            appVersion: item.version,
        };
        const winOpen = !this.isFreeLoginPlatform && window.open('', '_blank');
        WorkPlatformService.getAppUrl(params).then((res) => {
            if (res.success) {
                const homePage = item.homepages || {};
                const query = {
                    groupCode: '',
                    pageGroupCode: '',
                    pageCode: '',
                    ...homePage,
                    appVersion: item.version,
                    workflowCode: item.workflowCode,
                };
                const str = Object.entries(query)
                    .map((queryArr) => {
                    return queryArr.join('=');
                })
                    .join('&');
                let url = `${res.data}?${str}`;
                if (process.env.NODE_ENV !== 'production') {
                    url = `http://localhost${res.data.substring(res.data.indexOf('/api/launcher/'))}?${str}`;
                }
                this.setRecentApp(item.id);
                // window.location.href = url;
                try {
                    if (this.ISWECHAT && isPhone()) {
                        const token = localStorage.getItem('token');
                        const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                        window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                    }
                    else if ((this.IsDingTalk || this.ISWECHAT)) {
                        const token = localStorage.getItem('token');
                        const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                        if (this.needOpenBrowser) {
                            service.openLink(`${url}&token=${token}&userInfo=${userInfo}`);
                        }
                        else {
                            window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                        }
                    }
                    else {
                        if (!winOpen) {
                            throw new Error('浏览器新开弹窗未开启');
                        }
                        setTimeout(() => {
                            winOpen.location = url;
                        }, 100);
                    }
                }
                catch (e) {
                    Vue.prototype.$errorcatcher.showMessagePopup({
                        title: '错误提示！',
                        message: [e, '!'],
                        displayFooter: false,
                        correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
                    });
                }
            }
        });
    }
    setRecentApp(id) {
        const ids = this.recentIds;
        let idsArr = [];
        if (Array.isArray(ids)) {
            idsArr = ids.filter((item) => item !== id);
        }
        idsArr.unshift(id);
        this.setRecentIds(idsArr);
    }
};
__decorate([
    Prop({ default: [] })
], ComponentName.prototype, "list", void 0);
__decorate([
    workPlatformModule.State('recentIds')
], ComponentName.prototype, "recentIds", void 0);
__decorate([
    workPlatformModule.Action('setRecentIds')
], ComponentName.prototype, "setRecentIds", void 0);
__decorate([
    workPlatformModule.Action('setOnlineApps')
], ComponentName.prototype, "setOnlineApps", void 0);
__decorate([
    Global.State('needOpenBrowser')
], ComponentName.prototype, "needOpenBrowser", void 0);
ComponentName = __decorate([
    Component({
        name: 'app-page',
        components: {
            AppImage: () => import('@/components/global/app-image'),
        },
    })
], ComponentName);
export default ComponentName;
